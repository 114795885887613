import React, { lazy } from "react";
import styled from "styled-components";
import { Routes, Route, Navigate } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { HelmetProvider } from "react-helmet-async";
import { SessionProvider } from "../modules/shared/SessionProvider";
import { SidebarProvider } from "./context/SidebarContext";
// @ts-ignore
import { Windmill } from "@windmill/react-ui";
import { useSession } from "../modules/shared/SessionProvider";
import myStyle from "./myStyle";
import { Spinner } from "../ui-components/Spinner";

const Layout = lazy(() => import("./containers/Layout"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const PublicPageView = lazy(() => import("../modules/public/PublicView"));
const Page404 = lazy(() => import("./pages/404"));
const Login = lazy(() => import("./pages/Login"));
const PublicCancelEventView = lazy(
  () => import("../modules/public/CancelBookingView")
);

function App() {
  return (
    <HelmetProvider>
      <SessionProvider>
        <AccessibleNavigationAnnouncer />
        <SidebarProvider>
          <Windmill theme={myStyle}>
            <React.Suspense fallback={<Spinner />}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/create-account" element={<CreateAccount />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/*"
                  element={
                    <PrivateRoute>
                      <Layout />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/page/:pageSlug/:pageId"
                  element={
                    <Body>
                      <PublicPageView />
                    </Body>
                  }
                />
                <Route
                  path="/page/:pageSlug/:pageId/booking/:bookingId/cancel/:eventId"
                  element={
                    <Body>
                      <PublicCancelEventView />
                    </Body>
                  }
                />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </React.Suspense>
          </Windmill>
        </SidebarProvider>
      </SessionProvider>
    </HelmetProvider>
  );
}

export default App;

const PrivateRoute = ({ children }: React.PropsWithChildren) => {
  const { currentUser } = useSession();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

const Body = styled.main`
  display: flex;
  flex: 1;
  position: relative;
  margin: 0 auto;
  width: 1096px;
  max-width: 100%;

  .full-width & {
    width: 100%;
  }
`;

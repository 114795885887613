import React, { useState, useContext, useEffect } from "react";
import {
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";

import {
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore";

import { auth, db } from "../../firebase";
import { SignUpValues, UserDetail } from "../application/types";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const apiUrl = process.env.REACT_APP_APP_ENDPOINT_URL;

export const SessionContext = React.createContext<any>(undefined);

export const useSession = () => {
  const sessionContext = useContext(SessionContext);
  if (!sessionContext) {
    console.error("useSession cannot be used outside <SessionProvider>!");
    throw new Error("Missing session metadata");
  }
  return sessionContext;
};

export const SessionProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [userDetails, setUserDetails] = useState<UserDetail | null>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<{ signUp: string }>({ signUp: "" });
  const [pathToNavigateTo, setPathToNavigateTo] = useState("/");
  const navigate = useNavigate();

  useEffect(() => {
    setPathToNavigateTo(window.location.pathname);
  }, []);

  const signUp = async (values: SignUpValues) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const userDocRef = doc(db, "users", userCredential.user.uid);
      const userData = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: userCredential.user.email,
        userId: userCredential.user.uid,
        acceptTermsAndConditions: values.acceptTermsAndConditions,
        accountType: values.accountType,
      };
      await setDoc(userDocRef, userData);
      await axios.post(`${apiUrl}/api/send-mail`, {
        email: userCredential.user.email,
        subject: "Welcome to Njoin!",
        header: "Welcome to Njoin!",
        html: `
        <div>
          <p style="margin-bottom: 20px;">Dear ${values.firstName},</p>

          <p style="margin-bottom: 15px;">We're thrilled to welcome you to Njoin! Thank you for choosing us.</p>

          <p style="margin-bottom: 15px;">Your account has been successfully created, and you're now part of our community. Get ready to explore exciting features and enjoy a seamless experience.</p>

          <p style="margin-bottom: 15px;">Here are a few things you can do to get started:</p>

          <ol style="margin-bottom: 15px;">
            <li>Log in to your account using your email address: ${values.email}</li>
            <li>Explore our user-friendly interface and discover what Njoin has to offer.</li>
            <li>Customize your profile settings to make the most of your experience.</li>
          </ol>

          <p style="margin-bottom: 15px;">If you have any questions or need assistance, our support team is here to help. Simply reply to this email, and we'll be happy to assist you.</p>

          <p style="margin-bottom: 20px;">Once again, welcome aboard! We look forward to being part of your journey with Njoin.</p>

          <p>
            Best regards,<br />
            The Njoin Team
          </p>
        </div>
        `,
      });

      navigate(pathToNavigateTo.includes("/page/") ? pathToNavigateTo : "/");
    } catch (error: any) {
      console.debug(error);
      if (error.code === "auth/email-already-in-use") {
        setError({
          signUp:
            'User with this email already exists. Please log in or use the "Forgot Password" feature.',
        });
      }
    }
  };

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logOut = async () => {
    await signOut(auth);
    navigate("/login");
  };

  const resetPassword = (email: string) => {
    return sendPasswordResetEmail(auth, email);
  };

  const updateUserPassword = (password: string) => {
    return updatePassword(currentUser!, password);
  };

  const getUserDetails = async (userId?: string) => {
    try {
      if (userId) {
        const userDetailsData: any = [];

        const q = query(collection(db, "users"), where("userId", "==", userId));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          userDetailsData.push({ ...doc.data(), documentId: doc.id });
        });

        return userDetailsData[0];
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      throw error;
    }
  };

  const value = {
    currentUser,
    userDetails,
    signUp,
    login,
    logOut,
    resetPassword,
    updateUserPassword,
    error,
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user?.uid) {
        getUserDetails(user.uid).then((userDetails) => {
          setUserDetails(userDetails);
          setCurrentUser(user);
          setLoading(false);
        });
      } else {
        setCurrentUser(user);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <SessionContext.Provider value={value}>
      {!loading && children}
    </SessionContext.Provider>
  );
};

import styled from "styled-components";

export const Spinner = ({ label }: { label?: string }) => (
  <SpinnerWrapper>
    <TheSpinner />
    {label && <span>{label}</span>}
  </SpinnerWrapper>
);

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 12px;
  max-width: max-content;
  min-height: 100vh;
`;

const TheSpinner = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: auto;
  position: relative;
  border-top: 0.2em solid lightgray;
  border-right: 0.2em solid lightgray;
  border-bottom: 0.2em solid lightgray;
  border-left: 0.2em solid var(--brand-bg-color);
  animation: spin 1s infinite linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

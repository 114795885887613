import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { render } from "react-dom";
import ReactGA from "react-ga";

import "bootstrap/dist/css/bootstrap.min.css";
import Application from "./modules/application/Application";
import App from "./modules-new/App";
import "@stripe/stripe-js";
import "react-quill/dist/quill.snow.css";
import "./assets/css/tailwind.output.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-phone-number-input/style.css";

ReactGA.initialize("G-S59TWKM6ZZ");

const Root = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter basename="/">
      {/* <Application /> */}
      <App />
    </BrowserRouter>
  );
};

render(<Root />, document.getElementById("app"));
